<template>
  <div class="container-fluid">
    <Header />
    <div class="mx-auto mt-1 content-outer-container">
      <div class="wrap-Announcement">
        <div class="mx-auto content-inner-container boxContainer border-header">
          <div class="title-header">
            <img
              src="@/assets/images/announcement/notifications.svg"
              class="mr-2"
              alt=""
            />
            <span>{{ lbl['annocement'] }}</span>
          </div>
          <div class="search d-flex">
            <div class="searchContainer">
              <form autocomplete="off">
                <a-input
                  type="text"
                  class="searchInput"
                  :placeholder="lbl['annocement-search-placeholder']"
                  v-model="searchInput"
                  @keyup.enter="onEnterInput($event)"
                  autocomplete="off"
                />
              </form>
              <img
                @click="onEnterInput($event)"
                :src="require('@/assets/images/Search.png')"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="mx-auto content-inner-container">
          <div class="col-12">
            <div v-if="isSkeleton">
              <a-skeleton active />
            </div>
            <div v-else>
              <div v-if="listData.length > 0">
                <div
                  v-for="(item, index) in listData"
                  :key="index"
                  class="row pb-3 mb-4 border-header"
                >
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <div class="div-img-port mb-1">
                      <img
                        v-if="item.CoverImages"
                        class="img-port"
                        :src="coverImages(item.CoverImages)"
                        alt=""
                      />
                      <div v-if="item.HasPined" class="div-pushin d-flex">
                        <img
                          class="img-pushin"
                          src="@/assets/images/announcement/push_pin.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    <div class="row h-content">
                      <div class="col-xl-10 col-lg-9 col-md-8 col-sm-8 col-8">
                        <div class="div-title">
                          <img
                            v-if="!item.Read"
                            src="@/assets/images/item-inactive-icon.png"
                            class="announcement-icon main mr-2"
                            alt=""
                          />
                          {{ item.Title }}
                        </div>
                        <div class="div-detail" v-html="item.Contents"></div>
                        <div class="div-date">
                          {{ dateFormatData(item.StartDate) }}
                        </div>
                      </div>
                      <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4">
                        <div class="d-flex align-end h-100 justify-end">
                          <a
                            class="d-flex align-center mb-4 action-see-more"
                            @click="viewDetail(item.PartitionKey, item.RowKey)"
                          >
                            {{ lbl['user-permissions-see-more'] }}
                            <a-icon class="ml-1" type="right" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="box-pagination">
                  <div class="show-page">
                    {{ lbl['pagination-showing-text'] }} {{ current }}
                    {{ lbl['pagination-to-text'] }}
                    {{ Math.ceil(total / topPage) }}
                    {{ lbl['pagination-of-text'] }} {{ total }}
                    {{ lbl['pagination-entries-text'] }}
                  </div>
                  <a-pagination
                    :total="total"
                    :default-current="1"
                    :current="current"
                    :page-size="topPage"
                    :item-render="itemRender"
                    @change="onChangePage"
                  />
                </div>
              </div>
              <a-empty v-else class="py-5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Mixin from '@/mixin/Mixin'
import Header from '@/components/Layout/Header'
import Account from '@/helper/AccountHelper.js'
import BzbsAnnouncement from '@/core/Announcement/service/BzbsAnnouncement'

export default {
  name: 'Announcement',
  mixins: [Mixin],
  components: {
    Header,
  },
  data() {
    return {
      listData: [],
      dataAll: [],
      total: 0,
      current: 1,
      skip: 0,
      top: 100,
      topPage: 10,
      isSkeleton: true,
      searchInput: '',
    }
  },
  created() {
    this.handleFooter(true)
    Account.bzbsAnalyticTracking(
      'announcement_main',
      'announcement',
      'view_announcement',
      'on view',
    )
    this.init()
  },
  methods: {
    init() {
      this.getAnnouncementList(this.skip, this.top, null)
    },
    getAnnouncementList(skip, top, search) {
      this.dataAll = []
      this.isSkeleton = true
      return new Promise((resolve, reject) => {
        BzbsAnnouncement.apiAnnouncements(skip, top, search, null, null)
          .then(res => {
            console.log('apiAnnouncements : ', res)
            if (res.data.data) {
              this.total = res.data.data.length
              this.dataAll = res.data.data
              this.current = 1
              this.genList(this.current, this.topPage)
            }
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    genList(current, top) {
      this.listData = []
      if (this.dataAll.length > 0) {
        this.listData = this.dataAll.slice((current - 1) * top, current * top)
        this.isSkeleton = false
      } else {
        this.isSkeleton = false
      }
    },
    dateFormatData(value) {
      if (value) {
        var toDate = moment.unix(value).utc()
        var date = moment(toDate).format('DD/MM/YYYY HH:mm')
        return date
      } else {
        return ''
      }
    },
    onChangePage(page) {
      this.current = page
      this.genList(this.current, this.topPage)
    },
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a class="ant-pagination-item-link"> &#60;&#60; </a>
      } else if (type === 'next') {
        return <a class="ant-pagination-item-link"> &#62;&#62; </a>
      }
      return originalElement
    },
    onEnterInput() {
      this.current = 1
      this.topPage = 10
      this.getAnnouncementList(this.skip, this.top, this.searchInput)
    },
    viewDetail(partitionKey, rowKey) {
      this.$router.push('/Announcement/Detail/' + partitionKey + '/' + rowKey)
    },
    coverImages(Images) {
      if (Images) {
        let imgList = JSON.parse(Images)
        let img = imgList[0].Url + '?v=' + new Date().getTime()
        return img
      } else {
        return null
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/style/main.scss';
@import '../styles/announcement_main.scss';
</style>
